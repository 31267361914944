import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchMatch } from "./matchAPI";

export interface Match {
    id: string;
    name: string;
    code?: string;
    //useful for live analytics
    status: 'pending' | 'ongoing' | 'reporting' | 'disputed' | 'resolved',
    player_one: string | undefined;
    player_one_username: string | undefined;
    player_one_id: number | undefined;
    player_one_score: string;
    player_two: string | undefined;
    player_two_username: string | undefined;
    player_two_id: number | undefined;
    player_two_score: string;
    console?: string
    tourna_id: number;
    tourna_name: string | undefined;
    victor: number | undefined
    teamType?: string
    halfLength?: number;
    round: number;
    legacyDefending?: boolean;
    description: string,
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

export const fetchMatchAsync = createAsyncThunk(
    'match/',
    async (matchID: string) => {
        const response = await fetchMatch(matchID);
        return response.data
    }
);