import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, } from '../../app/store';
import { addTribunalMembership, removeTribunalMembership } from './tribunalMembershipAPI';

interface TribunalState {
    status: 'idle' | 'loading' | 'failed';
    errorMsg: string | undefined
    successMsg: string | undefined
}

const initialState: TribunalState = {
    status: 'idle',
    errorMsg: undefined,
    successMsg: undefined,
};

export const addTribunalMembershipAsync = createAsyncThunk(
    'addTribunalMembership/',
    async (tribunalObj: { tribunal_id: number, phone: string }) => {
        const response = await addTribunalMembership(tribunalObj);
        // The value we return becomes the `fulfilled` action payload
        console.log('add tribunal membership ', response);
        return response.data;
    }
);

export const removeTribunalMembershipAsync = createAsyncThunk(
    'removeTribunalMembership/',
    async (tribunalObj: { tribunal_id: number, phone: string }) => {
        const response = await removeTribunalMembership(tribunalObj);
        // The value we return becomes the `fulfilled` action payload
        console.log('remove tribunal membership ', response);
        return response.data;
    }
);