import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './Conflicts.module.css';
// import AddTournaModal from '../add-tourna-modal/AddTournaModal';
// import RefreshIcon from '../../assets/icons/512px-Refresh_icon.svg.png'
import { Conflict } from '../conflict/conflictSlice';
// import { randomString } from '../../utils';
// import { addTournaAsync, setTournaCode } from '../add-tourna-modal/addTournaModalSlice';
import ViewConflict from '../view-conflict/ViewConflict';
import { fetchMatchReportsAsync, setConflict, setMatchReports } from '../view-conflict/viewConflictSlice';
import { setErrorMsg } from '../../appSlice';
import { useNavigate } from 'react-router-dom';
import { Search } from '../search/Search';
import RefreshIcon from '../../assets/icons/512px-Refresh_icon.svg.png'
import { fetchConflictsAsync, setConflicts, setConflictsErrorMsg } from './conflictsSlice';
import { fetchMatchAsync } from '../match/matchSlice';

export function Conflicts() {
    const conflicts = useAppSelector<any>(state => state.conflictsList.conflicts);
    const [showModal, setShowModal] = useState(false);
    const [blurred, setBlurred] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const season = useAppSelector((state) => state.season);
    const conflErrorMsg = useAppSelector<any>(state => state.conflictsList.errorMsg)

    // input.replace(/\W/g, '')

    console.log(conflicts);
    useEffect(() => {
        if (conflErrorMsg) {
            dispatch(setErrorMsg(conflErrorMsg))
            dispatch(setConflictsErrorMsg(undefined))
        }
    }, [conflErrorMsg])

    // const newTourna = useAppSelector(state => state.addTourna.newTourna);
    // const saveDetails = () => {
    //     // setShowModal(false)
    //     console.log('saveDetails fired', newTourna);
    //     dispatch(setTournaCode(randomString(8)))
    //     console.log('saveDetails fired', newTourna);
    //     if (newTourna)
    //         dispatch(addTournaAsync(newTourna));
    //     else console.log('no details to save')
    // }

    const showConflict = (conflict: Conflict) => {
        if (!conflict.id) {
            dispatch(setErrorMsg("Error: conflict id absent"))
        } else {
            dispatch(setMatchReports(null));
            dispatch(setConflict(conflict));
            dispatch(fetchMatchReportsAsync(conflict.match));
            dispatch(fetchMatchAsync(conflict.match.toString()));
            connectToConflictChat();
            navigate(`/conflicts/${conflict.id}`)
        }
    }

    const connectToConflictChat = () => {

    }

    return (
        <div className={styles.Conflicts}>
            <div className={styles.Conflicts_top_row_div}>
                <div>
                    <img src={RefreshIcon} alt="refresh" width="30vw" height="30vw" onClick={() => {
                        dispatch(setConflicts([]))
                        dispatch(fetchConflictsAsync(season.value?.id || 0))
                    }} />
                </div>
                <div className={styles.Conflicts_search_div}>
                    <Search
                        model='conflict'
                        isSearching={false}
                    />
                </div>
            </div>
            <div className={styles.Conflicts_body_div}>
                <div className={styles[`Conflicts_list_div_${blurred}`]}>
                    {conflicts ? (conflicts.length > 0 ? conflicts.map((conflict: Conflict) =>
                        <div key={conflict.id} onClick={() => showConflict(conflict)}>
                            <ConflictStrip
                                id={conflict.id}
                                code={conflict.code}
                                status={conflict.status}
                                violator_id={conflict.violator_id}
                                match={conflict.match}
                                match_id={conflict.match_id}
                                created_at={conflict.created_at}
                                updated_at={conflict.updated_at}
                            />
                        </div>
                    ) : "no conflicts") : "no conflicts"}
                </div>
                <div className={styles.Conflicts_add_modal_div}>
                    {showModal && (
                        <ViewConflict closeModal={() => {
                            // dispatch(setShowMessageComponent(false))
                            setShowModal(false)
                        }
                        } />
                    )}
                </div>
            </div>
        </div>
    );
}

const ConflictStrip: React.FC<Conflict> = (data) => {
    const { id, code, status, violator_id, created_at, updated_at } = data;

    const color = status == "resolved" ? "green" : "red"

    return (
        <div style={{ width: "100%", cursor: "pointer" }} key={id} >
            <div>
                {/* <Link to={{ pathname: `/conflicts/${code}`, state: data }}> */}
                <div className="list-group-item list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">Conflict code: {code} </h5>
                        <h6 className="mb-1">Status:
                            <span style={{ color: color }}>{status} </span>
                        </h6>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <small className="text-muted"><span style={{ color: 'blue' }}>conflict createad at {created_at}</span></small>
                            <small>conflict resolved at {updated_at}</small>
                        </div>
                    </div>
                    <p className="mb-1">violator id: {violator_id}</p>
                    <small className="text-muted">{"final"} /=</small>
                </div>
                {/* </Link> */}
            </div>
        </div>
    );
};