/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector, } from "../../app/hooks";
import {
    MatchReport, postScoresAsync, setConflictWinner, ViewMatchInputs,
    setConflictPlayerOneScore, setConflictPlayerTwoScore, selectViewConflictDetails, clearConflictDetails, ViewConflictState,
} from "./viewConflictSlice";
// import { useHistory } from "react-router-dom";
import styles from "./ViewConflict.module.css";
import { selectTourna } from "../tourna/tournaSlice";
import { useNavigate } from "react-router-dom";
import { isTokenExpired, setErrorMsg } from "../../appSlice";
import Chat from "../chat/Chat";
import MatchReportSummary from "../match-report-summary/MatchReportSummary";
import { fetchConflictsAsync } from "../conflicts/conflictsSlice";
import { useSelector } from "react-redux";


interface ViewConflictProps {
    open?: boolean;
    closeModal: () => void;
}

const ViewConflict: React.FC<ViewConflictProps> = (props) => {
    const dispatch = useAppDispatch();
    const { handleSubmit, } = useForm<ViewMatchInputs>();
    const [selectedView, setSelectedView] = useState<'chat' | 'report'>('report');
    const conflictDetails: ViewConflictState = useAppSelector(selectViewConflictDetails)
    // const matchReports = useSelector(selectViewConflictDetails, (prev, next) => prev.matchReports !== next.matchReports).matchReports
    const matchReports = useSelector(selectViewConflictDetails).matchReports
    const tourna = useAppSelector(selectTourna)
    const [playerOneScore, setPlayerOneScore] = useState<number>(0);
    const [playerTwoScore, setPlayerTwoScore] = useState<number>(0);
    const [playerOneReport, setPlayerOneReport] = useState<MatchReport | undefined>();
    const [playerTwoReport, setPlayerTwoReport] = useState<MatchReport | undefined>();
    const [adminReport, setAdminReport] = useState<MatchReport | null>();
    const [adminPreviousReports, setAdminPreviousReports] = useState<MatchReport[]>()
    const [playerOnePreviousReports, setPlayerOnePreviousReports] = useState<MatchReport[]>()
    const [playerTwoPreviousReports, setPlayerTwoPreviousReports] = useState<MatchReport[]>()
    const [imgCollection, setImgCollection] = useState<File[]>([]);
    const [creatingNewReport, setCreatingNewReport] = useState<boolean>(false)
    const [reportBtnText, setReportBtnText] = useState<
        'report result' |
        'confirm player report' |
        'change my report'
    >('report result');
    const [playerOneUsername, setPlayerOneUsername] = useState<string>();
    const [playerTwoUsername, setPlayerTwoUsername] = useState<string>();
    const [adminUsername, setAdminUsername] = useState<string>('admin')

    const userID = useAppSelector(state => state.app.userID);
    const navigate = useNavigate();

    const app = useAppSelector((state) => state.app);

    function processMatchDetails() {
        if (creatingNewReport)
            return;
        console.log('conflictDetails ', conflictDetails)

        setPlayerTwoUsername(conflictDetails.match?.player_two_username);
        setPlayerOneUsername(conflictDetails.match?.player_one_username);

        let playerOneID: number | undefined = conflictDetails.match?.player_one_id;
        let playerTwoID: number | undefined = conflictDetails.match?.player_two_id;
        let adminPreviousReports: MatchReport[] = matchReports.filter((r: MatchReport) => {
            return (r.reporter_id === userID) && r.deleted_at
        });
        let playerOnePreviousReports: MatchReport[] = matchReports.filter((r: MatchReport) => {
            return (r.reporter_id === playerOneID) && r.deleted_at
        });
        let playerTwoPreviousReports: MatchReport[] = matchReports.filter((r: MatchReport) => {
            return (r.reporter_id === playerTwoID) && r.deleted_at
        });
        setAdminPreviousReports(adminPreviousReports);
        setPlayerOnePreviousReports(playerOnePreviousReports);
        setPlayerTwoPreviousReports(playerTwoPreviousReports);

        let adminReport: MatchReport | undefined = matchReports.find((r: MatchReport) => {
            return (r.reporter_id === userID) && !r.deleted_at
        });
        // opponentID is int so no need for conversion here
        let playerOneReport: MatchReport | undefined = matchReports.find((r: MatchReport) => {
            return (r.reporter_id === playerOneID) && !r.deleted_at
        });

        let playerTwoReport: MatchReport | undefined = matchReports.find((r: MatchReport) => {
            return (r.reporter_id === playerTwoID) && !r.deleted_at
        });

        console.log('playerOneReport ', playerOneReport)
        console.log('playerTwoReport ', playerTwoReport)
        console.log('adminReport ', adminReport)

        setPlayerOneReport(playerOneReport)
        setPlayerTwoReport(playerTwoReport)
        setAdminReport(adminReport)

        if (adminReport) {
            dispatch(setConflictPlayerOneScore(adminReport?.player_one_score));
            dispatch(setConflictPlayerTwoScore(adminReport?.player_two_score));
            setPlayerOneScore(Number.parseInt(adminReport?.player_one_score))
            setPlayerTwoScore(Number.parseInt(adminReport?.player_two_score))
            if (adminReport.player_one_score > adminReport.player_two_score)
                dispatch(setConflictWinner(conflictDetails.match?.player_one_id))
            if (adminReport.player_two_score > adminReport.player_one_score)
                dispatch(setConflictWinner(conflictDetails.match?.player_two_id))
            setReportBtnText('change my report')
        }

    }

    useEffect(() => processMatchDetails(), [
        // conflictDetails,
        conflictDetails.match?.player_one_score,
        conflictDetails.match?.player_two_score,
        userID,
        dispatch,
        creatingNewReport,
        conflictDetails.match,
        matchReports,
    ]);

    const clearPreviousReport = () => {
        setAdminReport(undefined)
        setCreatingNewReport(true);
        dispatch(setConflictPlayerOneScore('0'));
        setPlayerOneScore(0)
        dispatch(setConflictPlayerTwoScore('0'));
        setPlayerTwoScore(0)
        dispatch(setConflictWinner(undefined));
    }

    const resolveConflict = () => {
        console.log('conflictDetails ', conflictDetails);
        console.log('conflictDetails.match?.player_one_goals ... . . . . ', conflictDetails.match?.player_one_score)
        console.log('conflictDetails.match?.player_two_goals ... . . . . ', conflictDetails.match?.player_two_score)
        var formData = new FormData();
        // for (const key of Object.keys(imgCollection)) {
        //     formData.append('imgCollection', this.state.imgCollection[key])
        // }
        if (!app.accessToken) {
            dispatch(setErrorMsg('need to log in to do that.'));
            return;
        } else {
            if (isTokenExpired(app.accessToken)) {
                dispatch(setErrorMsg('need to log in to do that.'));
                return
            }
        }
        if (conflictDetails.match)
            if (conflictDetails.match.id) {
                if (userID) {
                    // let matchReport: MatchReport = {
                    //     match_id: conflictDetails.match?.id,
                    //     reporter_id: userID,
                    //     player_one_score: playerOneScore.toString(),
                    //     player_two_score: playerTwoScore.toString()
                    // }
                    // console.log('matchReport ... . . . . ', matchReport)
                    formData.append('match_id', conflictDetails.match?.id);
                    formData.append('reporter_id', userID.toString());
                    formData.append('player_one_score', playerOneScore.toString());
                    formData.append('player_two_score', playerTwoScore.toString());
                    for (let i = 0; i < imgCollection.length; i++) {
                        formData.append(`files`, imgCollection[i], imgCollection[i].name);
                    }
                    console.log('formData ... . . . . ', formData.toString())
                    dispatch(postScoresAsync(formData));
                    setTimeout(() => {
                        console.clear();
                        console.log('refetches the conflicts')
                        if (tourna.value?.id)
                            dispatch(fetchConflictsAsync(tourna.value.id))
                    }, 100);
                    close();
                } else dispatch(setErrorMsg('need to log in to do that.'));
            } else dispatch(setErrorMsg('no match id. contact admin.'));
        // document.removeEventListener("click", clickListener);
    }

    const onFileChange = (e: React.ChangeEvent<any>) => {
        console.log(e.target.files);
        setImgCollection(e.target.files)
    }

    const confirmPlayerReport = (report: MatchReport | undefined) => {
        if (!report)
            dispatch(setErrorMsg("No report present"))

        var formData = new FormData();
        if (!app.accessToken || !userID) {
            dispatch(setErrorMsg('need to log in to do that.'));
            return;
        } else {
            if (isTokenExpired(app.accessToken)) {
                dispatch(setErrorMsg('need to log in to do that.'));
                return
            }
        }
        if (report) {
            formData.append('match_id', report?.match_id.toString());
            formData.append('reporter_id', userID.toString());
            formData.append('player_one_score', report?.player_one_score);
            formData.append('player_two_score', report?.player_two_score);
            console.log('formData ... . . . . ', formData.toString())
            dispatch(postScoresAsync(formData));
            setTimeout(() => {
                console.clear();
                console.log('refetches the conflicts')
                if (tourna.value?.id)
                    dispatch(fetchConflictsAsync(tourna.value.id))
            }, 100);
            close();
        }
    }

    const close = () => {
        dispatch(clearConflictDetails());
        navigate(-1)
    }

    return (
        <div>
            <div className={styles.ViewConflict}>
                {conflictDetails.conflict && <div style={{ margin: "auto" }}>
                    Conflict status:
                    {(conflictDetails.conflict.status == "unresolved") ? <span style={{ color: "red" }}>unresolved </span>
                        : <span style={{ color: "green" }}>resolved </span>}

                </div>}

                <div className={styles.ViewConflict_toggle_strip}>
                    <div className={styles.ViewConflict_toggle}>
                        <div>
                            {selectedView === "report" && <button onClick={() => setSelectedView('chat')}>go to chat</button>}
                        </div>

                        {selectedView === "chat" && <button onClick={() => setSelectedView('report')}>{reportBtnText}</button>}
                    </div>
                </div>

                {selectedView === "chat" && <div>
                    <div className={styles.ViewConflict_chat_div} >

                        {/* <Chat messageType="matchMessage" /> */}
                        Coming soon
                    </div>
                </div>}

                {(selectedView === "report") && <div>

                    <div style={{ marginTop: '5vh' }}>
                        <div className={styles.ViewConflict_previous_reports_div} style={
                            {
                                display: "flex",
                                justifyContent: 'space-around'
                            }
                        }>
                            <div>
                                <span style={{ color: 'blue' }}>{adminUsername}'s previous reports</span>
                                <div>
                                    {adminPreviousReports && adminPreviousReports?.map(mr =>
                                        <MatchReportSummary
                                            matchReport={mr}
                                        />
                                    )}

                                </div>
                            </div>
                            <div>
                                <span style={{ color: 'red' }}>{playerOneUsername + "'s " + " previous reports"}</span>
                                <div>
                                    {playerOnePreviousReports && playerOnePreviousReports?.map(playerOneReport => <>
                                        {playerOneReport.id}
                                        <MatchReportSummary
                                            matchReport={playerOneReport}
                                        />
                                    </>)}
                                    {playerOnePreviousReports?.length == 0 && <>
                                        <h6>Yet to file</h6>
                                    </>}
                                </div>
                            </div>
                            <div>
                                <span style={{ color: 'red' }}>{playerTwoUsername + "'s " + " previous reports"}</span>
                                <div>
                                    {playerTwoPreviousReports && playerTwoPreviousReports?.map(playerTwoReport => <>
                                        {playerTwoReport.id}
                                        <MatchReportSummary
                                            matchReport={playerTwoReport}
                                        />
                                    </>)}
                                    {playerTwoPreviousReports?.length == 0 && <>
                                        <h6>Yet to file</h6>
                                    </>}
                                </div>
                            </div>
                        </div>
                        <hr style={{ width: "100%" }} />
                        <p style={{ color: 'blue' }}>is the information below correct? if not, clear and attach evidence</p>
                        <div className={styles.ViewConflict_previous_reports_div} style={
                            {
                                display: "flex",
                                justifyContent: 'space-around'
                            }
                        }>
                            <div>
                                <span style={{ color: 'blue' }}>{adminUsername}'s latest report</span>
                                <div>
                                    {adminReport && <div>

                                        <MatchReportSummary
                                            matchReport={adminReport}
                                        />
                                    </div>}
                                    {!adminReport && <>
                                        <h6>Yet to file</h6>
                                    </>}

                                </div>
                            </div>
                            <div>
                                <span style={{ color: 'red' }}>{playerOneUsername + "'s " + " latest report"}</span>
                                <div>
                                    {playerOneReport && <div>
                                        {playerOneReport.id}
                                        <MatchReportSummary
                                            matchReport={playerOneReport}
                                        />
                                    </div>}
                                    {!playerOneReport && <>
                                        <h6>Yet to file</h6>
                                    </>}
                                </div>
                            </div>
                            <div>
                                <span style={{ color: 'red' }}>{playerTwoUsername + "'s " + " latest report"}</span>
                                <div>
                                    {playerTwoReport && <div>
                                        {playerTwoReport.id}
                                        <MatchReportSummary
                                            matchReport={playerTwoReport}
                                        />
                                    </div>}
                                    {!playerTwoReport && <>
                                        <h6>Yet to file</h6>
                                    </>}
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <button onClick={clearPreviousReport}>clear and enter different data</button>
                            <button onClick={() => {
                                confirmPlayerReport(playerOneReport)
                            }}>{playerOneUsername}'s correct</button>
                            <button onClick={() => {
                                confirmPlayerReport(playerTwoReport)
                            }}>{playerTwoUsername}'s correct</button>
                        </div>

                    </div>
                    {(!adminReport && selectedView === "report") && <div>
                        <form className={styles.ViewConflict_form} encType="multipart/form-data">
                            <div className={styles.ViewConflict_name_field_div}>
                                <input onChange={(e) => {
                                    console.log("e:: ", e)
                                    const element = e.currentTarget as HTMLInputElement;
                                    console.log('setting PlayerOneScore to ', Number.parseInt(element.value))
                                    setPlayerOneScore(Number.parseInt(element.value));
                                    dispatch(setConflictPlayerOneScore(element.value));
                                    if (playerOneScore > playerTwoScore)
                                        dispatch(setConflictWinner(conflictDetails.match?.player_one_id));
                                    if (playerTwoScore > playerOneScore)
                                        dispatch(setConflictWinner(conflictDetails.match?.player_two_id));
                                }} type="number" placeholder={(conflictDetails.match?.player_one_id?.toString() === userID ? "My" : conflictDetails.match?.player_one_username + "'s ") + " score"} name="player-one-score" />
                            </div>

                            <div className={styles.ViewConflict_description_field_div}>
                                <input onInput={(e) => {
                                    const element = e.currentTarget as HTMLInputElement;
                                    console.log('setting PlayerTwoScore to ', Number.parseInt(element.value))
                                    setPlayerTwoScore(Number.parseInt(element.value));
                                    dispatch(setConflictPlayerTwoScore(element.value));
                                    if (playerOneScore > playerTwoScore)
                                        dispatch(setConflictWinner(conflictDetails.match?.player_one_id));
                                    if (playerTwoScore > playerOneScore)
                                        dispatch(setConflictWinner(conflictDetails.match?.player_two_id));
                                }} type="number" placeholder={(conflictDetails.match?.player_two_id?.toString() === userID ? "My" : conflictDetails.match?.player_two_username + "'s ") + " score"} name="player-two-score" />
                            </div>
                            <hr />
                            <div className={styles.AddTournaModal_form_match_type}>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={() => { dispatch(setConflictWinner(conflictDetails?.match?.player_one_id)); }} checked={playerOneScore > playerTwoScore} type="radio" name="winner" id="winner-player-one" />
                                    <label className="form-check-label" htmlFor="cardinality1">
                                        {(conflictDetails.match?.player_one_id?.toString() === userID ? "I" : conflictDetails.match?.player_one_username) + " won"}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={() => dispatch(setConflictWinner(conflictDetails.match?.player_two_id))} checked={playerTwoScore > playerOneScore} type="radio" name="winner" id="winner-player-two" />
                                    <label className="form-check-label" htmlFor="cardinality2">
                                        {(conflictDetails.match?.player_two_id?.toString() === userID ? "I" : conflictDetails.match?.player_two_username) + " won"}
                                    </label>
                                </div>
                            </div>
                            <hr />
                            <div className={styles.ViewConflict_upload_files_div}>
                                Attach evidence (pic/vid/sound)
                                <input type="file" multiple onChange={onFileChange} />
                            </div>
                            <div className={styles.ViewConflict_submit}>
                                <button onClick={close}>cancel</button>
                                <button onClick={handleSubmit(resolveConflict)}>report result</button>
                            </div>
                        </form>
                    </div>}
                </div>}
            </div>
        </div>
    );
};

export default ViewConflict;
