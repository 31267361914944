import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Message } from '../message/messageSlice';
import { fetchMessages } from './messagesAPI';

export interface MessagesState {
    channelID: string;
    messages: Message[];
    currentMaxMessageID: string | null;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: MessagesState = {
    channelID: '823483',
    messages: [],
    currentMaxMessageID: null,
    status: 'idle'
};

export const fetchMessagesAsync = createAsyncThunk(
    'messages/',
    async (channelID: string) => {
        const response = await fetchMessages(channelID);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setChannelID: (state, action: PayloadAction<string>) => {
            state.channelID = action.payload
        },
        setMessages: (state, action: PayloadAction<Message | any>) => {
            state.messages = action.payload
        },
        addMessage: (state, action: PayloadAction<any>) => {
            console.log('state.messages ', state.messages);
            console.log('action.payload ', action.payload);
            // Message {
            //     id?: string;
            //     channelID?: string;
            //     userID: string;
            //     username: string;
            //     content: string;
            // }
            // let res = {
            //     "payload_type": "message",
            //     "payload": {
            //         "user": {

            //         },
            //         "content": "skdl kjsd ldfd"
            //     }
            // }
            let res = JSON.parse(action.payload)
            let msg = {
                username: res.payload.user?.username,
                content: res.payload.content,
                userID: res.payload.user?.username,
            }
            // state.messages = [...state.messages, msg]
            state.messages.push(msg);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMessagesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMessagesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.messages = action.payload
            });
    },
});

export const { setMessages, setChannelID, addMessage } = messageSlice.actions;

export const selectMessages = (state: RootState) => state.messagesList.messages;
export const selectChannelID = (state: RootState) => state.messagesList.channelID;

export default messageSlice.reducer;
