import React, { useState, useEffect, useRef, } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector, } from "../../app/hooks";
import {
    loginAsync, LoginInput,
} from "./loginSlice";
// import { useHistory } from "react-router-dom";
import styles from "./Login.module.css";
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import { clearAppMessages, isTokenExpired, setErrorMsg, setSettingOTP, setSigningUp } from "../../appSlice";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { clearTournaMessages } from "../tourna/tournaSlice";
// import { clearTournaMessages } from "../tourna/tournaSlice";
// import BottomNav from "../bottom-nav/BottomNav";


interface LoginProps {
    children: React.ReactElement;
}

const Login = () => {
    // if (!Array.isArray(children)) return children
    const dispatch = useAppDispatch();
    const { handleSubmit, } = useForm<LoginInput>();
    const modalRef = useRef(null);
    const [phone, setPhone] = useState<string>('+254');
    const [password, setPassword] = useState<string>('');
    const app = useAppSelector(state => state.app);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [infoMessage, setInfoMessage] = useState<string>();
    const navigate = useNavigate();
    const errorMsg = useAppSelector((state) => state.app.errorMsg);
    const successMsg = useAppSelector((state) => state.app.successMsg);

    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(setErrorMsg(undefined));
            }, 4000);
        }
    }, [errorMsg, dispatch]);


    const login = (loginData: LoginInput) => {
        console.log('loginData ... . . . . ', loginData)
        console.log('code ... . . . . ', phone)
        console.log('password ... . . . . ', password)
        loginData.username = phone;
        loginData.password = password;
        console.log('loginData ... . . . . ', loginData)
        
        
        setErrorMessage(undefined);
        setInfoMessage("Logging in ...");
        setTimeout(() => {
            setInfoMessage(undefined);
            setErrorMessage('Failed to log in');
        }, 3000);
        dispatch(loginAsync(loginData))

    }

    useEffect(() => {
         
        if (!isTokenExpired(app.accessToken || "")) {
            dispatch(clearAppMessages());
            dispatch(clearTournaMessages());
            navigate("/tournas")
            console.log("valid token exists")
            // return <Navigate to="/sign-up" />;
        }
    }, [app.accessToken, dispatch])

    useEffect(() => {
        if (app.userID)
            dispatch(setSettingOTP(true));
    }, [app.userID, dispatch])

    useEffect(() => {
        console.log('login error message ', errorMessage)
        if (infoMessage && !errorMessage) {
            setErrorMessage(undefined);
            setInfoMessage(infoMessage);
        }
        if (errorMessage && !infoMessage) {
            setErrorMessage(errorMessage);
            setInfoMessage(undefined);
        }
    }, [infoMessage,  errorMessage]);


    return (
        <div>
            <div className={styles.Login} ref={modalRef}>
                <div className={styles.Login_toggle_strip}>
                    <div className={styles.Login_toggle}>
                        <button onClick={() => dispatch(setSigningUp(false))}>login</button>
                        <button onClick={() => {
                            dispatch(clearAppMessages())
                            return <Navigate to="/sign-up" />;
                        }}>sign up</button>
                    </div>
                    <img src={ArrowLeftIcon} alt="menu" width="50" height="40" onClick={() => navigate(-1)} />
                </div>

                <div>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '3vh' }}>
                    <h2>login</h2>
                    <img src={CloseIcon} alt="menu" width="50" height="40" onClick={close} />
                </div> */}
                    <div className={styles.Login_error_msg}>
                        {errorMessage}
                    </div>
                    {infoMessage && <div className={styles.Login_info_msg}>
                        {infoMessage}
                    </div>}

                    {errorMsg && (
                        <span style={{ marginLeft: "4vw", height: "auto", color: "black" }}>
                            {errorMsg}
                        </span>
                    )}

                    {successMsg && (
                        <span style={{ marginLeft: "4vw", height: "auto", color: "green" }}>
                            {successMsg}
                        </span>
                    )}
                    <form className={styles.Login_form}>
                        <div className={styles.Login_name_field_div}>
                            <input onInput={(e) => {
                                const element = e.currentTarget as HTMLInputElement;
                                setPhone(element.value);
                            }} type="text" autoComplete="phone" value={phone} placeholder="phone" name="phone" />
                        </div>

                        <div className={styles.Login_description_field_div}>
                            <input onInput={(e) => {
                                const element = e.currentTarget as HTMLInputElement;
                                setPassword(element.value);
                            }} type="password" autoComplete="new-password" value={password} placeholder="passcode" name="passcode" />
                        </div>
                        <div className={styles.Login_submit}>
                            <button onClick={handleSubmit(login)}>login</button>
                        </div>
                    </form>
                </div>
            </div>
            {/* <BottomNav /> */}
        </div>
    );
};

export default Login;
