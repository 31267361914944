import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './Rules.module.css';
import msgStyles from '../../message_styles.module.css';
import { setRulesValue, setRulesFilePath, } from './rulesSlice';
import RefreshIcon from '../../assets/icons/512px-Refresh_icon.svg.png';
import { clearAppMessages, clearTimouts, isTokenExpired, setErrorMsg } from '../../appSlice';
// import { Match } from '../match/matchSlice';
import { useNavigate } from 'react-router-dom';
import { createTournaTribunalAsync, getTournaOrganiserAsync } from '../tourna/tournaSlice';
import CloseIcon from '../../assets/icons/close_icon.png';
import SelectTribunalModal from '../select-tribunal-modal/SelectTribunalModal';


interface RulesInterface {
}

const Rules: React.FC<RulesInterface> = (props) => {
    const dispatch = useAppDispatch();
    const tourna = useAppSelector(state => state.tourna);
    const app = useAppSelector(state => state.app);
    const rules = useAppSelector(state => state.rulesData.rules);
    const [matchesPerRound, setMatchesPerRound] = useState<number>();
    const [numberOfRounds, setNumberOfRounds] = useState<number>(1);
    const [alreadyJoined, setAlreadyJoined] = useState<boolean>(false);
    const [seasonFull, setSeasonFull] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);
    const tournaSuccessMsg = tourna.successMsg, tournaErrorMsg = tourna.errorMsg;
    const navigate = useNavigate();
    const userID = useAppSelector(state => state.app.userID);
    const tournaOrganiser = useAppSelector(state => state.tourna.value?.organiser_id)
    const myTribunals = useAppSelector(state => state.tribunalState.myTribunals)
    const [showModal, setShowModal] = useState(false);
    const [blurred, setBlurred] = useState('');

    useEffect(() => {
        console.log("tournaOrganiser here ", tournaOrganiser)
        if (!tournaOrganiser && tourna.value?.id) {
            console.log("gets here 4837")
            dispatch(getTournaOrganiserAsync(tourna.value.id))
        }

    }, [tournaOrganiser, dispatch, tourna.value?.id])

    const fetchTournaOrganiser = () => {
        if (tourna.value?.id) {
            dispatch(getTournaOrganiserAsync(tourna.value.id))
            navigate(`/profile/${tourna.value?.organiser_id}/`)
        }
        else dispatch(setErrorMsg("no tourna detected"))
    }

    const composeTribunal = () => {
        // dispatch(clearAppMessages());
        // console.log("check here tourna.value?.tribunal_id", tourna.value?.tribunal_id)
        // if (tourna.value?.id && !tourna.value?.tribunal_id && (userID == tourna.value?.organiser_id))
        //     dispatch(createTournaTribunalAsync(tourna.value?.id))
        // navigate(`/tribunal/${tourna.value?.tribunal_id}/`)
        setShowModal(true)
    }

    useEffect(() => {
        if (showModal)
            setBlurred("blurred");
        else setBlurred("");
    }, [showModal]);

    return (
        <div>
            <div className={styles[`Rules_${blurred}`]}>
                <div>
                    {((tourna.value?.organiser_id !== userID) && !tournaOrganiser) && <button onClick={fetchTournaOrganiser}>view tourna organiser</button>}
                </div>

                <hr />

                {userID && (userID == tourna.value?.organiser_id) && rules && <div>
                    <button>edit rules</button>
                </div>}

                {userID && userID == tourna.value?.organiser_id && <div>
                    <button onClick={composeTribunal}>compose tribunal</button>
                    <hr />
                </div>}


                <div>
                    {tourna.value && <table>
                        <thead>
                            <tr>
                                <th>Rules</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>name</td>
                                <td>{tourna.value.name}</td>
                            </tr>
                            <tr>
                                <td>console</td>
                                <td>{tourna.value.console}</td>
                            </tr>
                            <tr>
                                <td>game</td>
                                <td>{tourna.value.game}</td>
                            </tr>
                            <tr>
                                <td>half length</td>
                                <td>{tourna.value.halfLength}</td>
                            </tr>
                            <tr>
                                <td>legacy defending</td>
                                <td>{tourna.value.legacyDefending}</td>
                            </tr>
                            <tr>
                                <td>private</td>
                                <td>{tourna.value.private}</td>
                            </tr>
                            <tr>
                                <td>description</td>
                                <td>{tourna.value.description}</td>
                            </tr>
                            <tr>
                                <td>status</td>
                                <td>{tourna.value.status}</td>
                            </tr>
                            <tr>
                                <td>entry fee</td>
                                <td>{tourna.value.entryFee}</td>
                            </tr>
                            <tr>
                                <td>number of participants</td>
                                <td>{tourna.value.numberOfParticipants}</td>
                            </tr>
                            <tr>
                                <td>tourna type</td>
                                <td>{tourna.value.tournaType}</td>
                            </tr>
                            <tr>
                                <td>code</td>
                                <td>{tourna.value.code}</td>
                            </tr>
                        </tbody>
                    </table>}
                </div>

                {userID && (userID == tourna.value?.organiser_id) && !rules && <div>
                    <button style={{ marginTop: '1vh' }}>upload additional rules pdf</button>
                </div>}
            </div>

            <div className={styles.Rules_trib_opt_modal_div}>
                {showModal && (
                    <SelectTribunalModal closeModal={() => setShowModal(false)} />
                )}
            </div>
        </div>
    );
}

export default Rules
