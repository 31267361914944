/* eslint-disable */ 
import React, { useEffect, useState } from 'react';

interface TimerProps {
    label: string
    deadline: string
    size: 'small'
}

export const Countdown: React.FC<TimerProps> = (props) => {

    const { deadline, label, size } = props;

    const [remainingTimeString, setRemainingTimeString] = useState<string>();
    const [textColor, setTextColor] = useState<'red' | 'green' | 'darkorange'>('green');

    useEffect(() => {
        let countDownDate = new Date(deadline).getTime();

        // Update the count down every 1 second
        let intervalID = setInterval(function () {
            console.log('running')

            // Get today's date and time
            let now = new Date().getTime();

            // Find the difference between now and the count down date
            let difference = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(difference / (1000 * 60 * 60 * 24));
            let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((difference % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            let remTimeString = days + "d " + hours + "h "
                + minutes + "m " + seconds + "s ";

            // If the count down is finished, write some text
            if (difference < 0) {
                clearInterval(intervalID);
                remTimeString = "PASSED \n (" + deadline + ")";
                setTextColor("darkorange")
            }

            if (minutes < 10 && days == 0 && hours == 0 && difference > 0) {
                setTextColor("red");
            }

            setRemainingTimeString(remTimeString)
        }, 1000);

        return () => {
            clearInterval(intervalID);
        }
    }, [deadline])

    return (
        <div>
            {size && <small>
                {label} : <span style={{
                    color: textColor
                }}>
                    {remainingTimeString}
                </span>
            </small>}
        </div>

    )
}
