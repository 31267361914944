import axios from "axios";
import config from '../../config.json';


export function fetchTournaParticipations(tournaID: string) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}tourna-participations/${tournaID}/get-tourna-participations/`)
            .then((res) => {
                console.log('fetched participations ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetchTournaParticipations ', e.message);
            });
    })
}

export function startTourna(tournaID: string) {
    return new Promise<any>((resolve) => {
        axios.post(`${config.baseUrl}tournas/${tournaID}/start-tourna/`)
            .then((res) => {
                console.log('started tourna ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('started tourna attempt ', e.message);
            });
    })
}

export function cancelTourna(tournaID: string) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}tournas/${tournaID}/cancel-tourna/`)
            .then((res) => {
                console.log('cancelled tourna ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('cancelled tourna attempt ', e.message);
            });
    })
}

export function startTournaRound(tournaID: string) {
    return new Promise<any>((resolve) => {
        axios.post(`${config.baseUrl}tournas/${tournaID}/start-round/`)
            .then((res) => {
                console.log('started tourna round', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('start tourna round attempt ', e.message);
            });
    })
}
export function getTournaOrganiser(tournaID: number) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}tournas/${tournaID}/get-organiser/`)
            .then((res) => {
                console.log('got organiser ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('failed to fetch organiser ', e.message);
            });
    })
}

export function createTournaTribunal(tournaID: number) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}tournas/${tournaID}/create-tribunal/`)
            .then((res) => {
                console.log('create tourna tribunal ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('failed to create tourna tribunal ', e.message);
            });
    })
}