import React, { useEffect, useState } from "react";
import {
    TribunalMembership,
    fetchTribunalMembersAsync
} from "./tribunalSlice";
// import { useHistory } from "react-router-dom";
import styles from "./Tribunal.module.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
// import ConfirmLoadCreditModal from "../load-credit/LoadCredit";
// import RefreshIcon from '../../assets/icons/512px-Refresh_icon.svg.png'
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import { setAppBarTitle } from "../app-bar/appBarSlice";
import AppBar from "../app-bar/AppBar";
import TribunalMembershipModal from "../tribunal-membership-modal/TribunalMembershipModal";
import RefreshIcon from '../../assets/icons/512px-Refresh_icon.svg.png'

const Tribunal: React.FC = () => {
    // const [showModal, setShowModal] = useState(false);
    const [blurred, setBlurred] = useState('');
    const dispatch = useAppDispatch();
    const app = useAppSelector(state => state.app);
    const navigate = useNavigate();
    const tourna = useAppSelector(state => state.tourna.value)
    const tribunal = useAppSelector(state => state.tribunalState.tribunal)
    const tribunalMemerships = useAppSelector(state => state.tribunalState.tribunalMembers)
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState<"add" | "remove">("add");
    const [slctdMmbrshp, setSlctdMmbrshp] = useState<TribunalMembership | undefined>()

    useEffect(() => {
        if (tourna?.tribunal_id) {
            dispatch(fetchTribunalMembersAsync(tourna?.tribunal_id))
        }
        if (tourna === undefined) {
            navigate("/tournas");
        } else dispatch(setAppBarTitle("Tribunal for " + tourna?.name));
    }, [tourna?.tribunal_id, dispatch, tourna, tribunal])

    // const saveDetails = () => {
    // console.log('saveDetails fired', newTourna);
    // if(newTourna)
    //     dispatch(saveTribunalDetails(newTourna));
    // else console.log('no details to save')
    // }

    const showAddMemberModal = () => {
        setAction("add")
        setShowModal(true)
    }

    const showRemoveMemberModal = (e: any) => {
        console.log("e ", e)
        let slctdMbsrhp = tribunalMemerships.find(t => t = e.target.value);
        setAction("remove")
        setSlctdMmbrshp(slctdMbsrhp)
        setShowModal(true)
    }

    useEffect(() => {
        if (showModal)
            setBlurred("blurred");
        else setBlurred("");
    }, [showModal]);

    return (
        <div className={styles.Tribunal}>
            <AppBar />
            {/* {(!app.userID || isTokenExpired(app.accessToken || '')) && <div className={styles.Tribunal_body_div}>
                Log in to view profile
            </div>} */}
            {/* {app.userID && !isTokenExpired(app.accessToken || '') && <div className={styles.Tribunal_body_div}> */}

            {tribunal && <div className={styles.Tribunal_body_div}>
                <div className={styles[`Tribunal_list_div_${blurred}`]}>
                    <div className={styles.Tribunal_image_div}>
                        <img src={ArrowLeftIcon} alt="menu" width="50" height="40" onClick={() => navigate(-1)} />
                        (back to tourna)
                    </div>
                    <div className={styles.Tribunal_details_div}>
                        <div>
                            <img src={RefreshIcon} alt="refresh" width="30vw" height="30vw" onClick={() => { if (tourna?.tribunal_id) dispatch(fetchTribunalMembersAsync(tourna?.tribunal_id)) }} />
                            <span>Tribunal Members</span>
                        </div>
                        <button onClick={showAddMemberModal} style={{ height: "6vh", float: "right", marginTop: "1vw" }}>
                            Add member
                        </button>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                {tribunalMemerships.map((mshp) => {
                                    return <tr key={mshp.id}>
                                        <td>{mshp.user}</td>
                                        <td><button value={mshp.id} onClick={showRemoveMemberModal}>remove</button></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        {/* <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                            fifa
                            <HorizontalCard label="" value="fifa-usrename" />
                            <button style={{height: "6vh", marginLeft: "1vw", marginTop: "1vw"}}>edit</button>
                        </div> */}
                    </div>
                    <div className={styles.Tribunal_stats_div}>
                        {/* <VerticalCard label={'balance'} value={profile?.balance || '0.00'} /> */}
                        {/* some stuff */}
                    </div>
                    <div className={styles.Tribunal_message_btn_div}>
                        <button style={{ height: '5vh', marginTop: '5vh' }} onClick={() => {
                            // console.log('button clicked ');
                            // navigate('/load-credit')
                        }}>revoke tribunal</button>
                    </div>
                </div>
                <div className={styles.Tribunal_add_modal_div}>
                    {showModal && (
                        <TribunalMembershipModal action={action} membership={slctdMmbrshp} closeModal={() => setShowModal(false)} />
                    )}
                </div>
                {/* </div>} */}
            </div>}
            {!tribunal && <div className={styles.Tribunal_body_div}>
                <div>
                    tribunal not found
                </div>
            </div>}
        </div>
    );
};




export default Tribunal;
