import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { TribunalMembership, createTribunalAsync, getTribunalsByCreatorAsync, setTribunal, setTribunalError } from "../tribunal/tribunalSlice";
import CloseIcon from '../../assets/icons/close_icon.png';
import { useNavigate } from "react-router-dom";
import { addTribunalMembershipAsync, removeTribunalMembershipAsync } from "./tribunalMembershipSlice";
import { setErrorMsg } from "../../appSlice";

interface TribunalMembershipModalInterface {
    action: "add" | "remove"
    closeModal: () => void;
    membership: TribunalMembership | undefined
}


const TribunalMembershipModal: React.FC<TribunalMembershipModalInterface> = (props) => {
    const { closeModal, action, membership } = props
    const tribunalState = useAppSelector(state => state.tribunalState)
    const tourna = useAppSelector(state => state.tourna.value);
    const tournaOrganiser = tourna?.organiser_id;
    const dispatch = useAppDispatch();
    const userID = useAppSelector(state => state.app.userID)
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
    const phoneInputRef = useRef<HTMLInputElement>(null)
    const tournaName = useAppSelector(state => state.tourna.value?.name)
    const [tribunalName, setTribunalName] = useState<string | undefined>(tournaName + " tribunal");
    const navigate = useNavigate();
    const [phone, setPhone] = useState<string>('+254');

    useEffect(() => {
        if (tribunalState.errorMsg) {
            dispatch(setErrorMsg(tribunalState.errorMsg));
            dispatch(setTribunalError(undefined))
        }
    }, [tribunalState.errorMsg])

    const toggleShowCreateForm = () => {
        setShowCreateForm(!showCreateForm);
    }

    const addUser = () => {
        if (phoneInputRef.current?.value)
            dispatch(addTribunalMembershipAsync({ phone: phoneInputRef.current?.value, tribunal_id: tribunalState.tribunal?.id || 0 }))
    }

    const removeUser = () => {
        console.log("removeUser enters ", tribunalState.tribunal?.creator_id, userID)
        if (membership?.user && tribunalState.tribunal?.creator_id === userID){
            console.log("removeUser enters ")
            dispatch(removeTribunalMembershipAsync({ phone: membership?.user, tribunal_id: tribunalState.tribunal?.id || 0 }))
        }
    }

    const editSelectedTribunal = () => {
        let selectedTribunal = tribunalState.myTribunals.find(t => t.id === tourna?.tribunal_id);
        dispatch(setTribunal(selectedTribunal));
        // setShowTribunal(true);
    }

    return (
        <>
            <div style={{ marginTop: '-2vh', width: "100%", height: "5vh" }}>
                <img src={CloseIcon} style={{ float: "right", marginTop: "2vh" }} alt="menu" width="50" height="40" onClick={closeModal} />
            </div>

            {action == "add" && <div>
                <label>Enter phone number</label>
                <input
                    onInput={(e) => {
                        const element = e.currentTarget as HTMLInputElement;
                        setPhone(element.value);
                    }}
                    value={phone}
                    ref={phoneInputRef}
                    style={{ width: "99%", marginTop: "2vh" }} />
                <div style={{ display: "flex", justifyContent: "space-around", marginTop: "2vh" }}>
                    <button onClick={addUser}>add user</button>
                </div>
            </div>}

            {action == "remove" && <div>
                <h6>remove user {membership?.user} ?</h6>
                <div style={{ display: "flex", justifyContent: "space-around", margin: "3vh" }}>
                    <button onClick={removeUser}>remove user</button>
                </div>
            </div>}
        </>
    )
}

export default TribunalMembershipModal
