import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { cancelTourna, createTournaTribunal, fetchTournaParticipations, getTournaOrganiser, startTourna, startTournaRound } from './tournaAPI';

export interface Tourna {
    id?: number;
    name: string;
    code: string;
    description: string;
    entryFee: number;
    game: string;
    private: boolean
    organiser_id?: number
    numberOfParticipants: number | null,
    // if tournaType is undefined tourna is just a single match (single pairing)
    tournaType: 'single' | 'double' | 'swiss' | 'groups' | undefined,
    status: 'running' | 'canceled',
    halfLength: number | undefined
    legacyDefending: boolean
    console: string,
    account_id?: number
    tribunal_id?: number
    createdAt?: string | undefined;
    updatedAt?: string | undefined;
    deletedAt?: string | undefined;
}

export interface TournaParticipation {
    id?: string,
    player_id: string,
    username: string,
    tourna_id: string,
    status: 'join_request' | 'active' | 'eliminated' | 'revoked'
    join_otp: string | undefined;
    createdAt: string | undefined;
    updatedAt: string | undefined;
}

export interface TournaParticipationData extends TournaParticipation {

}

export interface TournaState {
    selectedView: 'rules' | 'seasons' | 'posts',
    value: Tourna | undefined
    confirmedJoin: boolean
    status: 'idle' | 'loading' | 'failed';
    tournaParticipation: TournaParticipation | undefined
    tournaParticipations: TournaParticipation[];
    tournaParticipantsData: any
    errorMsg: string | undefined
    successMsg: string | undefined
}

const initialState: TournaState = {
    selectedView: 'seasons',
    confirmedJoin: false,
    value: undefined,
    status: 'idle',
    tournaParticipation: undefined,
    tournaParticipations: [],
    tournaParticipantsData: {},
    errorMsg: undefined,
    successMsg: undefined,
};

export const fetchTournaParticipationsAsync = createAsyncThunk(
    'tournaParticipations/',
    async (tournaID: string) => {
        const response = await fetchTournaParticipations(tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya fetchTournaParticipationsAsync', response.data);
        return response.data;
    }
);

export const startTournaAsync = createAsyncThunk(
    'startTourna/',
    async (tournaID: string) => {
        const response = await startTourna(tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya startTournaAsync', response.data);
        return response.data;
    }
);

export const cancelTournaAsync = createAsyncThunk(
    'cancelTourna/',
    async (tournaID: string) => {
        const response = await cancelTourna(tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya cancelTournaAsync', response.data);
        return response.data;
    }
);

export const startTournaRoundAsync = createAsyncThunk(
    'startTournaRound/',
    async (tournaID: string) => {
        const response = await startTournaRound(tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya startTournaRoundAsync', response.data);
        return response.data;
    }
);

export const fetchTournaPayoutAsync = createAsyncThunk(
    'tournaPayout/',
    async (tournaID: string) => {
        const response = await fetchTournaParticipations(tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya fetchTournaPayoutAsync', response.data);
        return response.data;
    }
);

export const getTournaOrganiserAsync = createAsyncThunk(
    'getTournaOrganiser/',
    async (tournaID: number) => {
        const response = await getTournaOrganiser(tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya getTournaOrganiserAsync', response.data);
        return response.data;
    }
);

export const createTournaTribunalAsync = createAsyncThunk(
    'createTournaTribunal/',
    async (tournaID: number) => {
        const response = await createTournaTribunal(tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya createTournaTribunal', response.data);
        return response.data;
    }
);

export const tournaSlice = createSlice({
    name: 'tourna',
    initialState,
    reducers: {
        setSelectedView: (state, action: PayloadAction<'rules' | 'seasons' | 'posts'>) => {
            state.selectedView = action.payload
        },
        setTourna: (state, action: PayloadAction<Tourna>) => {
            state.value = action.payload
        },
        setTournaParticipation: (state, action: PayloadAction<TournaParticipation | undefined>) => {
            state.tournaParticipation = action.payload
        },
        setTournaParticipantsData: (state, action: PayloadAction<any>) => {
            state.tournaParticipantsData = action.payload
        },
        setConfirmedJoin: (state, action: PayloadAction<boolean>) => {
            state.confirmedJoin = action.payload
        },
        setTournaErrorMsg: (state, action: PayloadAction<string | undefined>) => {
            state.errorMsg = action.payload
        },
        setTournaSuccessMsg: (state, action: PayloadAction<string | undefined>) => {
            state.successMsg = action.payload
        },
        clearTournaMessages: (state, action: PayloadAction) => {
            state.successMsg = undefined
            state.errorMsg = undefined
        },
        setTournaParticipations: (state, action: PayloadAction<TournaParticipation[]>) => {
            state.tournaParticipations = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTournaParticipationsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTournaParticipationsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload.code === 'success')
                    state.tournaParticipations = action.payload.data
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            })
            .addCase(startTournaAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(startTournaAsync.fulfilled, (state, action) => {
                console.log('res ya start tourna ', action.payload)
                state.status = 'idle';
                if (action.payload.code === 'success')
                    if (state.value)
                        state.value.status = "running"
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            })
            .addCase(startTournaRoundAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(startTournaRoundAsync.fulfilled, (state, action) => {
                console.log('res ya start tourna round ', action.payload)
                state.status = 'idle';
                if (action.payload.code === 'success') {
                    if (state.value)
                        state.value.status = "running"
                    state.successMsg = action.payload.detail
                }
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            })
            .addCase(fetchTournaPayoutAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTournaPayoutAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload.code === "success") {
                    state.errorMsg = undefined;
                    state.successMsg = action.payload.detail;
                    state.successMsg = "Successfully fetched tourna payout";
                    // state.tournaParticipation = action.payload.data
                }
                if (action.payload.code === 'error'){
                    console.log('requestJoinTournaOTPAsync error', action.payload.detail)
                    // state.errorMsg = action.payload.detail;
                    state.errorMsg = 'Failed to fetch tourna payout';
                    state.successMsg = undefined;
                }
            })
    },
});

export const { setSelectedView, setTourna, setConfirmedJoin, setTournaParticipation, setTournaParticipations,
    setTournaErrorMsg, setTournaSuccessMsg, setTournaParticipantsData, clearTournaMessages } = tournaSlice.actions;

export const selectSelectedView = (state: RootState) => state.tourna.selectedView;
export const selectTourna = (state: RootState) => state.tourna;

export default tournaSlice.reducer;
