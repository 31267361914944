import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appBarTitleReducer from '../features/app-bar/appBarSlice';
import messagesListReducer from '../features/messages/messagesSlice';
import tournasListReducer from '../features/tournas/tournasSlice';
import tournaReducer from '../features/tourna/tournaSlice';
import appReducer from '../appSlice';
import loginReducer from '../features/login/loginSlice';
import viewConflictReducer from '../features/view-conflict/viewConflictSlice';
// import profileStateReducer from '../features/profile/profileSlice';
// import lobbyReducer from '../features/lobby/lobbySlice';
import conflictsReducer from '../features/conflicts/conflictsSlice';
// import loungeReducer from '../features/lounge/loungeSlice';
import messageReducer from '../features/message/messageSlice';
import seasonsReducer from '../features/seasons/seasonsSlice';
import seasonReducer from '../features/season/seasonSlice';
// import newSeasonReducer from '../features/new-season-modal/newSeasonModalSlice';
import rulesReducer from '../features/rules/rulesSlice'
import tribunalStateReducer from '../features/tribunal/tribunalSlice';
import myResolvedConflictsReducer from '../features/my-resolved-conflicts/myResolvedConflictsSlice';
import selectTribunalStateReducer from '../features/select-tribunal-modal/selectTribunalSlice';
import userReducer from '../features/user/userSlice';

export const store = configureStore({
    reducer: {
        appBarTitle: appBarTitleReducer,
        messagesList: messagesListReducer,
        tournasList: tournasListReducer,
        // lobby: lobbyReducer,
        // lounge: loungeReducer,
        tourna: tournaReducer,
        message: messageReducer,
        app: appReducer,
        loginDetails: loginReducer,
        viewConflict: viewConflictReducer,
        // profileState: profileStateReducer,
        conflictsList: conflictsReducer,
        seasonsList: seasonsReducer,
        season: seasonReducer,
        // newSeason: newSeasonReducer,
        rulesData: rulesReducer,
        tribunalState: tribunalStateReducer,
        myResolvedConflictsList: myResolvedConflictsReducer,
        selectTribunalState: selectTribunalStateReducer,
        user: userReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
