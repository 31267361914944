import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import AppBar from "../app-bar/AppBar";
import { setAppBarTitle } from "../app-bar/appBarSlice";
import styles from "./TournasPage.module.css";
import { Tournas } from "../tournas/Tournas";
import { fetchTournasAsync } from "../tournas/tournasSlice";

const TournasPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTournasAsync());
        dispatch(setAppBarTitle('Tournas'))
    }, [dispatch])

    return (
        <div className={styles.TournasPage}>
            <AppBar />
            <div className={styles.TournasPage_list}>
                <Tournas />
            </div>
        </div>
    );
};

export default TournasPage;
