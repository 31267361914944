import axios from "axios";
import config from '../../config.json';

export function fetchConflicts(seasonID: number) {

    return new Promise<any>((resolve) =>
        axios.get(`${config.baseUrl}seasons/${seasonID}/get-season-conflicts/`)
            .then((res) => {
                console.log('fetched conflicts ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetchTournaConflicts ', e.message);
            })
    )
}

export function searchConflicts(searchString: string) {
    return new Promise<any>((resolve) =>
        axios.get(`${config.baseUrl}conflicts/${searchString}`,)
            .then((res) => {
                console.log('searched conflicts ', res);
                resolve(res);
            })
    )
}