import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface AppBarState {
    value: String
}

const initialState: AppBarState = {
    value: "no title",
};


export const appBarSlice = createSlice({
    name: 'appBarTitle',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAppBarTitle: (state, action: PayloadAction<String>) => {
            state.value = action.payload
        },
    },
});

export const { setAppBarTitle, } = appBarSlice.actions;

export const selectAppBarTitle = (state: RootState) => state.appBarTitle;

export default appBarSlice.reducer;
