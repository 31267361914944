import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tribunal } from '../tribunal/tribunalSlice';

export interface Rules {
    value: string,
    filePath: string,
}

export interface RulesState {
    id: string | undefined
    rules: Rules | undefined;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: RulesState = {
    id: undefined,
    rules: undefined,
    status: 'idle'
};

export const rulesSlice = createSlice({
    name: 'standings',
    initialState,
    reducers: {
        setRulesValue: (state, action: PayloadAction<string>) => {
            console.log('setting rules value', action.payload)
            if (state.rules)
                state.rules.value = action.payload
        },
        setRulesFilePath: (state, action: PayloadAction<string>) => {
            console.log('setting rules filepath', action.payload)
            if (state.rules)
                state.rules.filePath = action.payload
        },
    },
});

export const { setRulesValue, setRulesFilePath } = rulesSlice.actions;

export default rulesSlice.reducer;
