import axios from "axios";
import config from '../../config.json';

export function fetchMatch(matchID: string) {

    return new Promise<any>((resolve) =>
        axios.get(`${config.baseUrl}matches/${matchID}`)
            .then((res) => {
                console.log('fetched match ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetchMatch ', e.message);
            })
    )
}