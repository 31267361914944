import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, NavigateFunction } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    fetchTournasAsync, selectTournasList, setTournasList,
} from './tournasSlice';
import styles from './Tournas.module.css';
import RefreshIcon from '../../assets/icons/512px-Refresh_icon.svg.png'
import { clearTournaMessages, setTourna, setTournaParticipations, Tourna } from '../tourna/tournaSlice';
import { Search } from '../search/Search';
import { setConflicts } from '../conflicts/conflictsSlice';

export function Tournas() {
    const tournas = useAppSelector<Tourna[] | any>(selectTournasList);
    const [showModal, setShowModal] = useState(false);
    const [blurred, setBlurred] = useState('');
    const dispatch = useAppDispatch();
    const tournasIsSearching = useAppSelector(state => state.tournasList.isSearching);
    const userID = useAppSelector(state => state.app.userID);
    const [userIn20, setUserIn20] = useState<boolean>();
    const navigate = useNavigate()
    const [tournaments, setTournaments] = useState<Tourna & { navigate: NavigateFunction }[]>(tournas)

    useEffect(() => {
        if (userID)
            if (userID < 20)
                setUserIn20(true)
    }, [setUserIn20, userID]);

    useEffect(() => {
        if (showModal)
            setBlurred("blurred");
        else setBlurred("");
    }, [dispatch, showModal]);

    return (
        <div className={styles.Tournas}>
            <div className={styles.Tournas_top_row_div}>
                <div>
                    <img src={RefreshIcon} alt="refresh" width="30vw" height="30vw" onClick={() => {
                        dispatch(setTournasList([]))
                        dispatch(fetchTournasAsync())
                        setShowModal(true)
                        setShowModal(false)
                    }} />
                </div>
                <div className={styles.Tournas_search_div}>
                    <Search
                        model='tourna'
                        isSearching={tournasIsSearching}
                    />
                </div>
            </div>
            <div className={styles.Tournas_body_div}>
                {!tournasIsSearching && <div className={styles[`Tournas_list_div_${blurred}`]}>
                    <div>{tournas ? (tournas.length > 0 ? tournas.map((tourna: any) =>
                        <div key={tourna.id} onClick={() => {
                            dispatch(clearTournaMessages());
                            dispatch(setTournaParticipations([]));
                            dispatch(setConflicts([]))
                            dispatch(setTourna(tourna));
                            navigate(`/tournas/${tourna.code}`, {
                                state: {
                                    tournaCode: tourna.code,
                                }
                            })
                        }}>
                            <TournaStrip
                                id={tourna.id}
                                name={tourna.name}
                                code={tourna.code}
                                description={tourna.description}
                                entryFee={tourna.entryFee}
                                numberOfParticipants={tourna.numberOfParticipants}
                                tournaType={tourna.tournaType}
                            />
                        </div>

                    ) : "no tournas") : "no tournas"}</div>
                </div>}
                {tournasIsSearching && "Searching tournas ..."}
            </div>
        </div >
    );
}

interface UnusedKeys {
    status: string,
    game: string,
    private: boolean,
    organiser_id: number,
    halfLength: string,
    round: number,
    legacyDefending: boolean,
    console: string,
    currentLevel: string,
}

const TournaStrip: React.FC<Omit<Tourna, keyof UnusedKeys>> = (data) => {
    const { id, name, code, description, entryFee, numberOfParticipants,
        tournaType, } = data;
    const dispatch = useAppDispatch();

    // const navigate = useNavigate()

    return (
        <div style={{ width: "100%", cursor: "pointer" }} key={id} onClick={() => {

            console.log("should navigate");
            // navigate(`/tournas/${code}`);
        }}>
            <div className="list-group-item list-group-item-action">
                 <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{name} :  {code} </h5>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <small className="text-muted">
                            {tournaType === "swiss" && 
                                <span style={{ color: 'blue' }}>
                                    {!numberOfParticipants ? 'tourna open' : 'max count '+ numberOfParticipants}
                                </span>
                            }                            
                        </small>
                    </div>
                </div>
                <p className="mb-1">{description}</p>
                <small className="text-muted">{entryFee} /=</small>
            </div>
        </div>
    );
};
