import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from "./features/login/Login";
import { Provider } from 'react-redux';
import { store } from './app/store';
import Tourna from './features/tourna/Tourna';
import TournasPage from './features/tournas-page/TournasPage';
import ViewConflictPage from './features/view-conflict-page/ViewConflictPage';
import Season from './features/season/Season';
import ErrorBoundary from './features/error-boundary/ErrorBoundary';
import Tribunal from './features/tribunal/Tribunal';


function App() {

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={Login()} />
        <Route path="/log-in" element={Login()} />
        <Route path="/log-in" element={Login()} />
        <Route path="/tournas" element={TournasPage()} />
        <Route path="/tournas/:id" element={Tourna()} />
        {/* <Route exact path="/profile" component={ProfilePage} />
        <Route exact path="/profile/:id" component={Profile} /> */}
        <Route path="/conflicts/:id" element={ViewConflictPage()} />
        <Route path="/seasons/:id" element={Season({})} />
        <Route path="/tribunals/:id" element={Tribunal({})} />
        {/* <Route component={PageNotFound} /> */}
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
