import axios from "axios";
import { LoginInput } from "./loginSlice";
import config from '../../config.json';

export function login(loginInputs: LoginInput) {
    console.log('login inputs', loginInputs);
    return new Promise<any>((resolve) => {
        axios.post(`${config.baseUrl}api-token-auth/`, loginInputs)
            .then((res) => {
                console.log('logged in ', res);
                resolve(res);
            })
            .catch(err => {
                console.log('err ya logggggggin', err.data)
            })
    })
}
