import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchMessage } from './messageAPI';

export interface Message {
    id?: string;
    channelID?: string;
    username: string;
    userID: string;
    content: string;
}

export interface MessageState {
    value: Message;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: MessageState = {
    value: {channelID: '0', userID:'0', username: 'someguy',content: 'first message'},
    status: 'idle'
};

export const fetchMessageAsync = createAsyncThunk(
    'message/',
    async () => {
        const response = await fetchMessage();
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<Message>) => {
            state.value = action.payload
        },
    },
});

export const { setMessage } = messageSlice.actions;

export const selectMessage = (state: RootState) => state.message;

export default messageSlice.reducer;
