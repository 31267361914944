/* eslint-disable */
import React, { useEffect } from "react";
import styles from "./Season.module.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setAppBarTitle } from "../app-bar/appBarSlice";
import { clearSeasonMessages, setSelectedView, } from "./seasonSlice";
import AppBar from "../app-bar/AppBar";
import BottomNav from "../bottom-nav/BottomNav";
import Chat from "../chat/Chat";
import { Posts } from "../posts/Posts";
import Rules from "../rules/Rules";
import { useNavigate } from "react-router-dom";
import { Seasons } from "../seasons/Seasons";
import { Match } from "../match/matchSlice";
import { setMyResolvedConflicts } from "../my-resolved-conflicts/myResolvedConflictsSlice";
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import { Conflicts } from "../conflicts/Conflicts";
import { Conflict } from "../conflict/conflictSlice";
import { fetchConflictsAsync } from "../conflicts/conflictsSlice";

const POINTS_PER_WIN = 3
const POINTS_PER_DRAW = 1

const Season: React.FC = () => {
    const season = useAppSelector((state) => state.season.value);
    const app = useAppSelector((state) => state.app);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const conflictsList = useAppSelector<Conflict[] | any>(state => state.conflictsList);
    // const [standings, setStandings ] = useState<any>()

    useEffect(() => {
        console.log("alaaa,   llll", season);
        // if (tourna != undefined) {
        //     dispatch(setAppBarTitle("Season: " + season.value.count));
        // } else navigate("/tournas")
        // }
    }, [dispatch, navigate, season]);

    useEffect(() => {
        if (season?.id) {
            dispatch(fetchConflictsAsync(season.id));
        } else
            console.log("season has no id, possibly from a previous attempt at creation");
    }, [dispatch, season]);

    return (
        <div className={styles.Season}>
            <AppBar />
            <div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                <img src={ArrowLeftIcon} alt="menu" width="25" height="20" />
                <span style={{ fontSize: '80%' }}>(back to tourna details)</span>
            </div>
            <Conflicts />
            <BottomNav />
        </div>
    );
};

export default Season;
