import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Match } from '../match/matchSlice';
import { fetchMyResolvedConflicts } from './myResolvedConflictsAPI';

export interface MyResolvedConflictsState {
    myResolvedConflicts: Match[];
    status: 'idle' | 'loading' | 'failed';
    errorMsg: string | undefined;
}

const initialState: MyResolvedConflictsState = {
    myResolvedConflicts: [],
    status: 'idle',
    errorMsg: undefined,
};

export const fetchMyResolvedConflictsAsync = createAsyncThunk(
    'myResolvedConflicts/',
    async (myResolvedConflictsPayload: {tournaID: string, userID: string}) => {
        const response = await fetchMyResolvedConflicts(myResolvedConflictsPayload);
        return response.data
    }
);


export const myResolvedConflictsSlice = createSlice({
    name: 'myResolvedConflicts',
    initialState,
    reducers: {
        setMyMatch: (state, action: PayloadAction<Match | any>) => {
            state.myResolvedConflicts = action.payload
        },
        addSingleMyMatch: (state, action: PayloadAction<Match>) => {
            state.myResolvedConflicts.push(action.payload);
        },
        addManyMyResolvedConflicts: (state, action: PayloadAction<Match[]>) => {
            console.log('adding my matches ...', action.payload)
            state.myResolvedConflicts.concat(action.payload);
        },
        setMyResolvedConflicts: (state, action: PayloadAction<Match[]>) => {
            console.log('setting my matches ...', action.payload)
            state.myResolvedConflicts = action.payload
        },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyResolvedConflictsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMyResolvedConflictsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                console.log("my matches afterwards rrrrr")
                console.log(action.payload.data)
                if (action.payload.code === 'success') {
                    console.log("my matches afterwards rrrrr byayaya")
                    state.myResolvedConflicts = action.payload.data
                    console.log(state.myResolvedConflicts)
                }
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            });
    },
});

export const { setMyResolvedConflicts, addManyMyResolvedConflicts, } = myResolvedConflictsSlice.actions;

export const selectMyResolvedConflicts = (state: RootState) => state.myResolvedConflictsList;

export default myResolvedConflictsSlice.reducer;
