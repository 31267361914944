/* eslint-disable */ 
import React, { useEffect, useState } from "react";
import styles from "./Tourna.module.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setAppBarTitle } from "../app-bar/appBarSlice";
import { setSelectedView, clearTournaMessages } from "./tournaSlice";
import AppBar from "../app-bar/AppBar";
import { useNavigate } from "react-router-dom";
import { fetchConflictsAsync } from "../conflicts/conflictsSlice";
import { Conflict } from "../conflict/conflictSlice";
import Rules from "../rules/Rules";
import { Seasons } from "../seasons/Seasons";
import { Posts } from "../posts/Posts";
import BottomNav from "../bottom-nav/BottomNav";
import { fetchSeasonsAsync } from "../seasons/seasonsSlice";

const Tourna = () => {
    const tourna = useAppSelector((state) => state.tourna);
    const app = useAppSelector((state) => state.app);
    const tournaName = tourna?.value?.name;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const conflictsList = useAppSelector<Conflict[] | any>(state => state.conflictsList);

    useEffect(() => {
        console.log("alaaa,   llll", tourna.value);
        if (tourna.value != undefined) {
            dispatch(setAppBarTitle("Tourna: " + tourna.value.name));
        }
    }, [dispatch, tournaName, navigate, tourna.value]);

    useEffect(() => {
        if (tourna.value?.id) {
            dispatch(fetchSeasonsAsync(tourna.value.id));
        }
    }, [dispatch, tourna.value,]);


    return (
        <div className={styles.Tourna}>
            <AppBar />
            <div className={styles.Tourna_nav_bar}>
            <button onClick={() => {
                    dispatch(clearTournaMessages());
                    dispatch(setSelectedView("rules"))
                }}>
                    rules
                </button>
                <button onClick={() => dispatch(setSelectedView("seasons"))}>
                    seasons
                </button>
                <button onClick={() => dispatch(setSelectedView("posts"))}>
                    posts
                </button>
                {/* <button onClick={() => dispatch(setSelectedView('chat'))}>tourna chat</button> */}
            </div>
            {tourna.selectedView === "rules" && (
                <div className={styles.Tourna_standings_div}>
                    <Rules />
                </div>
            )}
            {tourna.selectedView === "seasons" && (
                <div className={styles.Tourna_schedule_div}>
                    <Seasons />
                </div>
            )}
            {tourna.selectedView === "posts" && (
                <div className={styles.Tourna_posts_div}>
                    <Posts />
                </div>
            )}
            <BottomNav />
        </div>
    );
};

export default Tourna;
