import axios from "axios";
import config from '../../config.json';

const headers = {
    'Content-Type': 'multipart/form-data'
}

export function postScores(matchReport: FormData) {
    return new Promise<any>((resolve) => {
        axios.post(`${config.baseUrl}match-reports/report-match-result/`, matchReport, {headers: headers})
            .then((res) => {
                console.log('posted scores ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('postScores ', e.message);
            });
    })
}

export function resolveConflict(tournaID: number) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}matches/${tournaID}/start-tourna/`)
            .then((res) => {
                console.log('started tourna ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('started match attempt ', e.message);
            });
    })
}

export function fetchConflict(match_id: number) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}matches/${match_id}/fetch-match-conflict/`)
            .then((res) => {
                console.log('fetched conflict ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetch conflict attempt ', e.message);
            });
    })
}

export function fetchMatchReports(match_id: number) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}matches/${match_id}/fetch-match-reports/`)
            .then((res) => {
                console.log('fetched conflict ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetch conflict attempt ', e.message);
            });
    })
}