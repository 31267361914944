import axios from "axios";
import config from '../../config.json';

export function assignTribunal(tribunalSelectObj: { tourna_id: number, tribunal_id: number }) {
    return new Promise<any>((resolve) => {
        // axios.get(`${config.baseUrl}accounts/${user_id}/update-account/`,)
        axios.post(`${config.baseUrl}tournas/${tribunalSelectObj.tourna_id}/assign-tribunal/`, tribunalSelectObj)
            .then((res) => {
                console.log('create tourna tribunal ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('failed to create tourna tribunal ', e.message);
            });
    })
}
