import axios from "axios";
import config from './config.json';

export function refreshAccessToken(token: string | null) {
    return new Promise<any>((resolve) => {
        axios.post(`${config.baseUrl}api-token-refresh/`, { "refresh": token })
            .then((res) => {
                console.log('new access ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetchTournaParticipations ', e.message);
            });
    })
}