// A mock function to mimic making an async request for data
export function fetchMessages(channelID: string) {
    return new Promise<{ code: string, data: any }>((resolve) =>
        setTimeout(() => resolve({
            code: "success", data: [
                
            ]
        }), 1100)
    );
}
