import React, { useState, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import styles from "./AppBar.module.css";
import HamburgerIcon from "../../assets/icons/Hamburger_icon.svg.png";
import CloseIcon from "../../assets/icons/close_icon.png";
import SideBar from "../side-bar/SideBar";
import LoginIcon from "../../assets/icons/twotone_login_black_24dp.png";
import LogOutIcon from "../../assets/icons/twotone_logout_black_24dp.png";
import {
    isTokenExpired,
    refreshAccessTokenAsync,
    setAccess,
    setAccessToken,
    setErrorMsg,
    setUserId,
} from "../../appSlice";
import Login from "../login/Login";
import { useNavigate } from "react-router-dom";

const AppBar: React.FC = () => {
    const appBarTitle = useAppSelector<string | any>(
        (state) => state.appBarTitle
    );
    const accessToken = useAppSelector<string | undefined>(
        (state) => state.app.accessToken
    );

    const [showMenu, setShowMenu] = useState<Boolean>(false);
    const errorMsg = useAppSelector((state) => state.app.errorMsg);
    const sideBarRef = useRef(null);
    const [invalidToken, setInvalidToken] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("refresh");
        setAccessToken("");
        dispatch(setAccess(""));
        dispatch(setUserId(0));
    };

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(setErrorMsg(undefined));
            }, 4000);
        }
    }, [errorMsg, dispatch]);

    useEffect(() => {
        let interval: number;

        if (accessToken) {
            setAccessToken(accessToken);

            interval = window.setInterval(() => {
                if (accessToken) setInvalidToken(isTokenExpired(accessToken));
                if (invalidToken) {
                    console.log("refreshing invalid token")
                    dispatch(refreshAccessTokenAsync(localStorage.getItem("refresh")));
                }
                console.log("checking token")
            }, 5000);

            return () => clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [accessToken]);

    return (
        <div style={{
            backgroundColor: invalidToken ||
                !accessToken ||
                isTokenExpired(accessToken) ? "red" : "", height: "5vh"
        }}>
            {showMenu ? (
                <div>
                    <img
                        src={CloseIcon}
                        alt="menu"
                        width="50"
                        height="40"
                        onClick={() => setShowMenu(false)}
                    />
                    <div className={styles.AppBar_sidebar} ref={sideBarRef}>
                        <SideBar />
                    </div>
                </div>
            ) : (
                <div>
                    <img
                        style={{ display: "inline" }}
                        src={HamburgerIcon}
                        alt="menu"
                        width="50"
                        height="40"
                        onClick={() => setShowMenu(true)}
                    />
                    <div style={{ display: "inline", height: "3vh" }}>
                        {!errorMsg &&
                            appBarTitle.value}

                        {errorMsg && (
                            <span style={{
                                marginLeft: "4vw", marginBottom: "1vh", height: "auto", fontSize: "90%", color: invalidToken ||
                                    !accessToken ||
                                    isTokenExpired(accessToken) ? "white" : "red"
                            }}>
                                {errorMsg}
                            </span>
                        )}

                        {/* {successMsg && (
                            <span style={{ marginLeft: "4vw", height: "auto", color: "green" }}>
                                {successMsg}
                            </span>
                        )} */}
                    </div>
                    {(!accessToken || isTokenExpired(accessToken)) && (
                        <div style={{ float: "right" }}>
                            <img

                                src={LoginIcon}
                                alt="login"
                                width="50"
                                height="40"
                                onClick={() => navigate("/log-in")}
                            />
                        </div>
                    )}
                    {accessToken && !isTokenExpired(accessToken) && (
                        <img
                            style={{ float: "right" }}
                            src={LogOutIcon}
                            alt="logout"
                            width="50"
                            height="40"
                            onClick={logout}
                        />
                    )}
                </div>
            )}

            <div className={styles.Tournas_add_modal_div}>
                {showLogin && (
                    <Login />
                )}
            </div>
        </div>
    );
};

export default AppBar;
