import { useEffect, useState } from 'react';
import { useAppDispatch, useDebounce } from '../../app/hooks';
import { searchConflictsAsync } from '../conflicts/conflictsSlice';
import { searchTournasAsync } from '../tournas/tournasSlice';
import styles from './Search.module.css';

interface SearchProps {
    model: 'tourna' | 'match' | 'lounge' | 'account' | 'conflict' | 'season'
    isSearching: boolean
}

export const Search: React.FC<SearchProps> = (props) => {

    const { model } = props

    const [searchString, setSearchString] = useState<string>('');
    //implement debounce
    const debouncedSearchString = useDebounce(searchString, 500)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (debouncedSearchString) {
            switch (model) {
                case 'tourna':
                    dispatch(searchTournasAsync(debouncedSearchString));
                    break;
                case 'conflict':
                    dispatch(searchConflictsAsync(debouncedSearchString));
                    break;
                case 'account':
                    // dispatch(searchAccountsAsync(debouncedSearchString));
                    break;
            }

        }
    }, [debouncedSearchString, dispatch, model])

    return (
        <div className={styles.Search}>
            <input style={{width: "50%"}} placeholder='Search' onChange={(e) => setSearchString(e.target.value)} />
        </div>
    );
}
