import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    fetchSeasonsAsync,
} from './seasonsSlice';
import styles from './Seasons.module.css';
// import AddTournaModal from '../add-tourna-modal/AddTournaModal';
import RefreshIcon from '../../assets/icons/512px-Refresh_icon.svg.png'
import { clearSeasonMessages, setSeason, setSeasonParticipations, Season } from '../season/seasonSlice';
import { Search } from '../search/Search';
import { Countdown } from '../countdown/Countdown';
import { clearTournaMessages } from '../tourna/tournaSlice';
import { setConflicts } from '../conflicts/conflictsSlice';
// import NewSeasonModal from '../new-season-modal/NewSeasonModal';

export function Seasons() {
    const seasonsList = useAppSelector<Season[] | any>(state => state.seasonsList);
    const tourna = useAppSelector<Season[] | any>(state => state.tourna.value);
    const [showModal, setShowModal] = useState(false);
    const [blurred, setBlurred] = useState('');
    const dispatch = useAppDispatch();
    const seasonsIsSearching = useAppSelector(state => state.seasonsList.isSearching);
    const userID = useAppSelector(state => state.app.userID);
    const [userIn20, setUserIn20] = useState<boolean>();
    const navigate = useNavigate()

    useEffect(() => {
        if (userID)
            if (userID < 20)
                setUserIn20(true)
    }, [setUserIn20, userID]);

    useEffect(() => {
        if (showModal)
            setBlurred("blurred");
        else setBlurred("");
    }, [dispatch, showModal]);

    // input.replace(/\W/g, '')
    console.log(seasonsList);


    return (
        <div className={styles.Tournas}>
            <div className={styles.Tournas_top_row_div}>
                <div>
                    <img src={RefreshIcon} alt="refresh" width="30vw" height="30vw" onClick={() => dispatch(fetchSeasonsAsync(tourna.id))} />
                </div>
                <div className={styles.Tournas_search_div}>
                    <Search
                        model='season'
                        isSearching={seasonsIsSearching}
                    />
                </div>
                {/* Uncomment below when want to open create functionality to public */}
                {!showModal && userIn20 && userID == tourna.organiser_id && <div className={styles.Tournas_add_btn}>
                    <button onClick={() => setShowModal(true)}>create match/season</button>
                </div>}
            </div>
            <div className={styles.Tournas_body_div}>
                {!seasonsIsSearching && <div className={styles[`Tournas_list_div_${blurred}`]}>
                    {seasonsList.status !== "idle" ? "no seasons" :
                        <div>{seasonsList?.seasons ? (seasonsList.seasons.length > 0 ? seasonsList.seasons.map( (season: any) =>
                            <div key={season.id} onClick={() => {
                                dispatch(clearTournaMessages());
                                dispatch(setSeasonParticipations([]));
                                dispatch(setConflicts([]))
                                dispatch(setSeason(season));
                                navigate(`/seasons/${season.id}`, {
                                    state: {
                                        seasonID: season.code,
                                    }
                                })
                            }}>
                                <SeasonStrip
                                    id={season.id}
                                    count={season.name}
                                    code={season.code}
                                    round={season.description}
                                    status={season.entryFee}
                                    tourna_id={season.numberOfParticipants}
                                    joinedParticipantsCount={season.joinedParticipantsCount}
                                    registrationDate={season.registrationDate}
                                    startDate={season.startDate}
                                    payout={season.openTournament}
                                    currentLevel={season.tournaType}
                                />
                            </div>) : "no seasons") : "no seasons"}</div>
                    }
                </div>}
                {seasonsIsSearching && "Searching seasons ..."}
                <div className={styles.Tournas_add_modal_div}>
                    {/* {showModal && (
                        <NewSeasonModal closeModal={() => setShowModal(false)} />9
                    )} */}
                </div>
            </div>
        </div>
    );
}


const SeasonStrip: React.FC<Season> = (data) => {
    const { id, count, joinedParticipantsCount, registrationDate, winner_id,
        tourna_id, startDate, status, payout, round, code, currentLevel } = data;
    // const tourna = useAppSelector(state => state.tourna.value)

    return (
        <div style={{ width: "100%" }} key={id} onClick={() => {
            // dispatch(clearSeasonMessages());
            // dispatch(setSeasonParticipations([]));
            // dispatch(setSeason(data));
        }}>
            <div className="list-group-item list-group-item-action">
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">Season :  {count} </h5>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <small className="text-muted">
                            <span style={{ color: 'blue' }}>
                                {/* {!tourna?.numberOfParticipants ? 'tourna open ('+ joinedParticipantsCount +' joined)' : joinedParticipantsCount + ' of ' + tourna.numberOfParticipants + ' joined'} */}
                                {joinedParticipantsCount + ' joined'}
                            </span>
                        </small>
                        <small>reg. deadline  ({registrationDate})</small>
                        {registrationDate && <>
                            <Countdown
                                deadline={registrationDate}
                                label="registration deadline"
                                size='small'
                            />
                        </>}
                        {startDate && <>
                            <Countdown
                                deadline={startDate}
                                label="tourna date"
                                size='small'
                            />
                        </>}
                    </div>
                </div>
                <p className="mb-1">season {code}</p>
                <small className="text-muted">round {round} /=</small>
            </div>
        </div>
    );
};
