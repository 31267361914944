import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createTribunalAsync, getTribunalsByCreatorAsync, setTribunal } from "../tribunal/tribunalSlice";
import CloseIcon from '../../assets/icons/close_icon.png';
import { useNavigate } from "react-router-dom";
import { assignTribunalAsync, setSelectTribunalModalError } from "./selectTribunalSlice";
import { setErrorMsg } from "../../appSlice";

interface SelectTribunalModalInterface {
    closeModal: () => void;
}


const SelectTribunalModal: React.FC<SelectTribunalModalInterface> = (props) => {
    const { closeModal } = props
    const tribunalState = useAppSelector(state => state.tribunalState)
    const selectTribunalState = useAppSelector(state => state.selectTribunalState)
    const tourna = useAppSelector(state => state.tourna.value);
    const tournaOrganiser = tourna?.organiser_id;
    const dispatch = useAppDispatch();
    const userID = useAppSelector(state => state.app.userID)
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
    const tribunalNameRef = useRef<HTMLInputElement>(null)
    const tournaName = useAppSelector(state => state.tourna.value?.name)
    const [tribunalName, setTribunalName] = useState<string | undefined>(tournaName + " tribunal");
    const [showTribunal, setShowTribunal] = useState<boolean>(false)
    const navigate = useNavigate();

    useEffect(() => {
        if (selectTribunalState.errorMsg) {
            dispatch(setErrorMsg(selectTribunalState.errorMsg));
            dispatch(setSelectTribunalModalError(undefined))
        }
    }, [selectTribunalState.errorMsg])

    useEffect(() => {
        if (tournaOrganiser == userID && userID) {
            dispatch(getTribunalsByCreatorAsync(userID))
        }
    }, [tournaOrganiser, dispatch]);

    const toggleShowCreateForm = () => {
        setShowCreateForm(!showCreateForm);
    }

    const createTribunal = () => {
        setShowTribunal(true);
        dispatch(createTribunalAsync({ name: tribunalNameRef.current?.value || "organiser: " + tournaOrganiser, creator_id: userID || 0 }))
    }

    useEffect(() => {
        if (showTribunal && tribunalState.tribunal)
            navigate("/tribunals/" + tribunalState.tribunal.id)
    }, [showTribunal, tribunalState.tribunal])

    const recordTribunalSelect = (e: any) => {
        console.log(e)
        if (tourna?.id)
            if (tourna.tribunal_id !== e.target.value)
                dispatch(assignTribunalAsync({
                    tribunal_id: e.target.value,
                    tourna_id: tourna?.id
                }))
    }

    const editSelectedTribunal = () => {
        let selectedTribunal = tribunalState.myTribunals.find(t => t.id === tourna?.tribunal_id);
        dispatch(setTribunal(selectedTribunal));
        setShowTribunal(true);
    }

    return (
        <>
            <div style={{ marginTop: '-2vh', width: "100%", height: "5vh" }}>
                <img src={CloseIcon} style={{ float: "right", marginTop: "2vh" }} alt="menu" width="50" height="40" onClick={closeModal} />
            </div>

            {!showCreateForm && <div>
                {tribunalState.status !== "idle" ? "no tribunals" :
                    <div>
                        <h6 style={{marginLeft: "3vw"}}>select tribunal</h6>
                        <select value={tourna?.tribunal_id} style={{ marginLeft: "30%" }} onChange={recordTribunalSelect}>
                            <option>no tribunal selected</option>
                            {tribunalState.myTribunals ?
                                (tribunalState.myTribunals.length > 0 ? tribunalState.myTribunals.map(t => {
                                    return <option key={t.id} value={t.id}>{t.name}</option>
                                })
                                    : "no tribunals") : "no tribunals"}
                        </select>
                    </div>
                }
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", marginTop: "2vh" }}>
                    {tourna?.tribunal_id && <button onClick={editSelectedTribunal}>
                        edit selected tribunal
                    </button>}
                    <hr />
                    <button onClick={toggleShowCreateForm}>
                        create new tribunal
                    </button>
                </div>

            </div>}
            {showCreateForm && <div>
                <label>Edit tribunal name</label>
                <input
                    value={tribunalName}
                    onChange={(e) => { e.preventDefault(); setTribunalName(e.target.value) }}
                    ref={tribunalNameRef}
                    style={{ width: "99%", marginTop: "2vh" }} />
                <div style={{ display: "flex", justifyContent: "space-around", marginTop: "2vh" }}>
                    <button onClick={toggleShowCreateForm}>cancel</button>
                    <button onClick={createTribunal}>create tribunal</button>
                </div>
            </div>}


        </>
    )
}

export default SelectTribunalModal
